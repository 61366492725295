import {
  LOGIN_ACTION_FAILURE,
  LOGIN_ACTION_REQUEST,
  LOGIN_ACTION_SUCCESS,
} from '../constants/actionTypes';
import { setLocal } from '../API/Api';

const initialState = {
  fetching: false,
  success: false,
  failure: false,
  token: null,
  permissions: [],
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ACTION_REQUEST:
      return { ...state, fetching: true };

    case LOGIN_ACTION_SUCCESS:
      const Token = `Bearer ${action.data.data.accessToken}`;
      localStorage.setItem('Token', Token);
      localStorage.setItem('profileName', action.data.data.fullName);
      localStorage.setItem('permissions', JSON.stringify(action.data.data.permissions));

      setLocal(Token);
      return {
        ...state,
        fetching: false,
        success: true,
        token: action.data.data.accessToken,
        permissions: action.data.data.permissions,
      };

    case LOGIN_ACTION_FAILURE:
      return { ...state, fetching: false, failure: true };
    default:
      return { ...state };
  }
};
