import { compose, createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import rootReducers from "../reducers/index";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';


export const history = createBrowserHistory();
  const reactRouterMiddleware = routerMiddleware(history);
  const middleware = [
    thunk,
    reactRouterMiddleware,
  ];


const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["login"],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : compose;

const initialState = {};
const store = createStore(
  persistedReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    process.env.NODE_ENV === "development" ? reduxDevTools : compose
  )
);
const persistor = persistStore(store);

export { store, persistor };







